export class Court {
  constructor(court) {
    this.id = court.id;
    this.name = court.name;
    this.label = court.label;
    this.typeId = court.typeId;
    this.addressId = court.addressId;
    this.numberOfCourtRooms = court.numberOfCourtRooms;
    this.chiefRegistrar = court.chiefRegistrar;
    this.type = new CourtType(court.type);
    this.address = new CourtAddress(court.address);
    this.createdAt = new Date(court.createdAt);
    this.updatedAt = new Date(court.updatedAt);
  }
}

export class CourtType {
  constructor(courtType) {
    this.id = courtType.id;
    this.name = courtType.name;
    this.label = courtType.label.replaceAll(" ", "-");
    this.logoUrl = courtType.logoUrl;
    this.createdAt = new Date(courtType.createdAt);
    this.updatedAt = new Date(courtType.updatedAt);
  }
}

export class CourtAddress {
  constructor(courtAddress) {
    this.id = courtAddress.id;
    this.street = courtAddress.street;
    this.city = courtAddress.city;
    this.state = courtAddress.state;
    this.createdAt = new Date(courtAddress.createdAt);
    this.updatedAt = new Date(courtAddress.updatedAt);
  }

  get fullAddress() {
    return `${this.street}, ${this.city}, ${this.state} State`
  }

  get halfAddress() {
    return `${this.street}, ${this.city}`
  }
}

class Slugged {
  constructor(slug, name) {
    this.slug = slug;
    this.name = name
  }
}

export const CaseFileType = Object.freeze({
  caseFile: new Slugged('case-file', 'Case File'),
  coverLetter: new Slugged('cover-letter', 'Cover Letter'),
  supportingDocuments: new Slugged('supporting-documents', 'Supporting Documents'),
})