import store from '@/store';
import authService from '../../services/auth.service'
import roleGuard from './role-guard';

const authGuard = (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = authService.getToken();
    if (token) {
      try {
        store.dispatch('auth/verifyUser')
        return roleGuard(to, from, next)
      } catch {
        next({ name: 'login' })
      }
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
}

export default authGuard