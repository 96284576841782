import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { Court, CourtAddress, CourtType } from "@/models/court";

const rootURL = 'court';

const state = {
  list: Pagination.prototype.createEmpty(),
  addressList: Pagination.prototype.createEmpty(),
  typeList: Pagination.prototype.createEmpty(),
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    commit("SET_COURTS", response.data.data)
  },
  get: async (context, id) => {
    const response = await httpService.get(`${rootURL}/${id}`)
    return new Court(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/${data.id}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/${id}`)
    dispatch("getAll")
    return response.data.message
  },
  createAddress: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}/address`, data)
    dispatch("getAllAddresses")
    return new CourtAddress(response.data.data);
  },
  getAllAddresses: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}/address`, { params: options })
    commit("SET_COURT_ADDRESSES", response.data.data)
  },
  getAddress: async (context, id) => {
    const response = await httpService.get(`${rootURL}/address/${id}`)
    return new CourtAddress(response.data.data)
  },
  updateAddress: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/address/${data.id}`, data)
    dispatch("getAllAddresses")
    return response.data.message
  },
  deleteAddress: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/address/${id}`)
    dispatch("getAllAddresses")
    return response.data.message
  },
  createType: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}/type`, data)
    dispatch("getAllTypes")
    return new CourtType(response.data.data);
  },
  getAllTypes: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}/type`, { params: options })
    commit("SET_COURT_TYPES", response.data.data)
  },
  getType: async (context, id) => {
    const response = await httpService.get(`${rootURL}/type/${id}`)
    return new CourtType(response.data.data)
  },
  updateType: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/type/${data.id}`, data)
    dispatch("getAllTypes")
    return response.data.message
  },
  deleteType: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/type/${id}`)
    dispatch("getAllTypes")
    return response.data.message
  },
}

const mutations = {
  SET_COURTS: (state, courts) => {
    const pagination = new Pagination(courts, "courts/getAll")
    pagination.result = pagination.result.map((court) => new Court(court))
    state.list = pagination
  },
  SET_COURT_ADDRESSES: (state, courtAddresses) => {
    const pagination = new Pagination(courtAddresses, "courts/getAllAddresses")
    pagination.result = pagination.result.map((court) => new CourtAddress(court))
    state.addressList = pagination
  },
  SET_COURT_TYPES: (state, courtTypes) => {
    const pagination = new Pagination(courtTypes, "courts/getAllTypes")
    pagination.result = pagination.result.map((court) => new CourtType(court))
    state.typeList = pagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
    state.addressList = Pagination.prototype.createEmpty()
    state.typeList = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}