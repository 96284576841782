import { User } from "@/models/user"
import authService from "@/services/auth.service"
import { httpService } from "@/services/http.service"

const state = {
  user: authService.getUser(),
}

const actions = {
  login: async ({ dispatch }, data) => {
    const response = await httpService.post('auth/login', data)
    authService.setToken(response.data.data.token);
    await dispatch('verifyUser')
  },
  validateUser: async (context, data) => {
    const response = await httpService.post('auth/validate', data)
    return response.data.data
  },
  forgotPassword: async (context, data) => {
    const response = await httpService.post('auth/forgot-password', data)
    return response.data.message
  },
  changePassword: async (context, data) => {
    const response = await httpService.patch('auth/me', data)
    return response.data.messsage
  },
  userVerification: async (context, data) => {
    const response = await httpService.post('user/verification', { ...data.passwords }, { params: { ...data.auth } })
    return response.data.messsage
  },
  verifyUser: async ({ commit }) => {
    const response = await httpService.get('auth/me', { params: { _silent_: true } })
    authService.setUser(response.data.data)
    commit('SET_USER', response.data.data)
  },
  logout: async ({ commit }) => {
    authService.removeUser()
    authService.removeToken()
    // commit('staff/CLEAR', null, { root: true })
    commit('CLEAR')
  },
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = new User(user)
  },
  CLEAR: (state) => {
    state.user = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}