import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { CaseFile } from "@/models/case-file";

const rootURL = 'case';

const state = {
  list: Pagination.prototype.createEmpty(),
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    commit("SET_CASEFILES", response.data.data)
  },
  get: async (context, id) => {
    const response = await httpService.get(`${rootURL}/${id}`)
    return new CaseFile(response.data.data)
  },
  update: async (context, data) => {
    const caseId = data.id
    delete data.id
    const response = await httpService.put(`${rootURL}/${caseId}`, data)
    return response.data.message;
  },
  addDocument: async (context, data) => {
    const response = await httpService.post(`${rootURL}/document`, data, { headers: { "Content-Type": "multipart/form-data" } })
    return response.data.message
  },
  deleteDocument: async (context, data) => {
    const response = await httpService.delete(`${rootURL}/document/${data}`)
    return response.data.message
  },
  makeVerdict: async (context, data) => {
    const response = await httpService.post(`${rootURL}/verdict`, data)
    return response.data.message
  },
  addReport: async (context, data) => {
    const response = await httpService.post(`${rootURL}/report`, data)
    return response.data.message
  },
  makeNote: async (context, data) => {
    const response = await httpService.post(`${rootURL}/note`, data)
    return response.data.message
  },
  adjourn: async (context, data) => {
    const response = await httpService.post(`${rootURL}/adjournment`, data)
    return response.data.message
  },
}

const mutations = {
  SET_CASEFILES: (state, caseFiles) => {
    const caseFilesPagination = new Pagination(caseFiles, "cases/getAll")
    caseFilesPagination.result = caseFilesPagination.result.map((caseFile) => new CaseFile(caseFile, true))
    state.list = caseFilesPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}