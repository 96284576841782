import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
} from '@heroicons/vue/outline'

const AttorneyGeneralNavigation = [
  { name: 'Dashboard', icon: HomeIcon, current: true, href: '/dashboard' },
  // {
  //   name: 'Correctional Facilities',
  //   icon: UsersIcon,
  //   current: false,
  //   children: [
  //     { name: 'Overview', href: '/agf/correctional-facilities' },
  //     { name: 'Correctional Facilities Reports', href: '/agf/correctional-facilities-reports' },
  //   ],
  // },
  // {
  //   name: 'Arresting Agencies',
  //   icon: UsersIcon,
  //   current: false,
  //   children: [
  //     { name: 'Overview', href: '/agf/arresting-agencies' },
  //     { name: 'Arresting Agency Reports', href: '/agf/arresting-agency-reports' },
  //   ],
  // },
  {
    name: 'Courts',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/courts' },
      { name: 'Court Reports', href: '/agf/court-reports' },
    ],
  },
  {
    name: 'New Cases',
    icon: FolderIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/case-files/new' },
      { name: 'New Cases Reports', href: '/agf/new-cases-reports' },
    ],
  },
  {
    name: 'Civil Litigation Matters',
    icon: FolderIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/civil-litigation-matters/case-files' },
      { name: 'Civil Litigation Reports', href: '/agf/civil-litigation-reports' },
    ],
  },
  {
    name: 'Civil Appeal',
    icon: CalendarIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/agf/civil-appeal-overview' },
      { name: 'Civil Appeal Reports', href: '/agf/civil-appeal-reports' },
    ],
  },
  {
    name: 'Department of Public Prosecution',
    icon: InboxIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/agf/dpp-overview' },
      { name: 'Department of Public Prosecution Reports', href: '/agf/dpp-reports' },
    ],
  },
  {
    name: 'System Reporting',
    icon: ChartBarIcon,
    current: false,
    children: [
      { name: 'Overview', href: '#' },
      { name: 'Audit Trail', href: '#' },
    ],
  },
]

const PermanentSecretaryNavigation = [
  { name: 'Dashboard', icon: HomeIcon, current: true, href: '/dashboard' },
  // {
  //   name: 'Correctional Facilities',
  //   icon: UsersIcon,
  //   current: false,
  //   children: [
  //     { name: 'Overview', href: '#' },
  //     { name: 'Inmate Reports', href: '#' },
  //   ],
  // },
  {
    name: 'Lawyers & Firms',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Overview', href: '#' },
      { name: 'Lawyers & Firms Reports', href: '#' },
    ],
  },
]

const DirectorNavigation = [
  { name: 'Dashboard', icon: HomeIcon, current: true, href: '/dashboard' },
  {
    name: 'Lawyers',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Overview', href: '#' },
      // { name: 'Lawyers & Firms Reports', href: '#' },
    ],
  },
  {
    name: 'Case Files',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/case-files' },
    ],
  },
]

const LawyerNavigation = [
  { name: 'Dashboard', icon: HomeIcon, current: true, href: '/dashboard' },
  {
    name: 'Cases',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Dub Case View', href: '/civil-litigation/lawyer/case-view' },
      { name: 'Case View', href: '/case-files' },
      { name: 'Case Reports ---', href: '#' },
    ],
  },
]

const RegistrarNavigation = [
  { name: 'Dashboard', icon: HomeIcon, current: true, href: '/dashboard' },
  {
    name: 'Cases',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'All Case Files', href: '/case-files' },
      { name: 'Add Case File', href: '/add-case-file' },
    ],
  },
  {
    name: 'Filing & Services',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'All Filings', href: '/case-filings' },
      { name: 'Pending Filing', href: '/pending-filings' },
    ],
  },
]

export { AttorneyGeneralNavigation, PermanentSecretaryNavigation, DirectorNavigation, LawyerNavigation, RegistrarNavigation }