import { AccessLevel } from '@/models/user'
import notificationService from '@/services/notification.service'
import authService from '../../services/auth.service'

const roleGuard = async (to, from, next) => {
  if (to.matched.some(record => record.meta.allowedAccessLevels)) {
    if (authService.getUser().isAdmin || to.meta.allowedAccessLevels.includes(authService.getUser().access.accessLevel)) {
      next()
    } else {
      const delay = from.name != 'login' ? 2000 : 10;

      if (from.name !== 'login')
        notificationService.warning("You are not authorized at access this page.")

      setTimeout(() => {
        switch (authService.getUser().access.accessLevel) {
          case AccessLevel.REGISTRAR:
            next({ name: "Registry Dashboard" })
            break;
          case AccessLevel.LAWYER:
            next({ name: "DPP Overview" })
            break;
          case AccessLevel.DIRECTOR:
            next({ name: "CivilDashboard" })
            break;
          default:
            next({ name: "Dashboard" })
            break;
        }
      }, delay);
    }
  } else {
    next()
  }
}

export default roleGuard