import { createStore } from 'vuex'
import auth from './modules/auth';
import users from './modules/users';
import cases from './modules/cases';
import courts from './modules/courts';
import loading from './modules/loading';
import { httpService } from '@/services/http.service';
import { Statistics } from '@/models/statistics';

export default createStore({
  state: {
    pdf: null,
    statistics: Statistics.prototype.init()
  },
  getters: {
  },
  mutations: {
    SET_PDF: (state, pdfURL) => {
      state.pdf = pdfURL
    },
    SET_STATISTICS: (state, statistics) => {
      state.statistics = new Statistics(statistics)
    },
  },
  actions: {
    displayPDF: async ({ commit }, data) => {
      commit('SET_PDF', data)
    },
    clearPDF: async ({ commit }) => {
      commit('SET_PDF', null)
    },
    getStatistics: async ({ commit }) => {
      const response = await httpService.get("case/meta")
      commit("SET_STATISTICS", response.data.data)
    }
  },
  modules: {
    auth,
    users,
    cases,
    courts,
    loading
  }
})
