import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Icon } from '@iconify/vue';

import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue"
import PaginationComponent from "@/components/PaginationComponent.vue"
import LoadingComponent from "@/components/LoadingComponent.vue"
import SelectComponent from "@/components/SelectComponent.vue"
import ButtonComponent from "@/components/ButtonComponent.vue"
import InputComponent from "@/components/InputComponent.vue"

import './registerServiceWorker'
import './assets/styles/index.css'


const app = createApp(App);

app.use(store);
app.use(router);

app.component("BreadcrumbComponent", BreadcrumbComponent);
app.component("PaginationComponent", PaginationComponent);
app.component("LoadingComponent", LoadingComponent);
app.component("SelectComponent", SelectComponent);
app.component("ButtonComponent", ButtonComponent);
app.component("InputComponent", InputComponent);
app.component("IconComponent", Icon);

app.mount('#app');
