import AppLayoutDashboard from '@/layouts/AppLayoutDashboard.vue'
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'
import { AccessLevel } from '@/models/user'

export const routes = [
	{
		path: '/',
		name: 'login',
		component: () => import('@/views/auth/LoginView.vue'),
		meta: {
			title: 'Login',
			layout: AppLayoutDefault,
		},
	},
	{
		path: '/forgot-password',
		name: 'Forgot Password',
		component: () => import('@/views/auth/ForgotPasswordView.vue'),
		meta: {
			title: 'Forgot Password',
			layout: AppLayoutDefault,
		},
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: () => import('@/views/auth/CreatePasswordView.vue'),
		meta: {
			title: 'Reset your Password',
			layout: AppLayoutDefault,
		},
	},
	{
		path: '/verify',
		name: 'Invitation',
		component: () => import('@/views/auth/CreatePasswordView.vue'),
		meta: {
			title: 'Accept your Invitation',
			layout: AppLayoutDefault,
		},
	},
	{
		path: "/settings",
		name: "UserSettings",
		component: () =>
			import("@/views/UserSettings.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.DIRECTOR, AccessLevel.LAWYER, AccessLevel.REGISTRAR],
		},
	},
	// ABOVE IS FINE
	//
	//
	// 
	// 
	// ABOVE IS FINE
	{
		path: "/agf/correctional-facilities",
		name: "Correctional Facilities",
		component: () =>
			import("@/views/agf/CorrectionalFacilities.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/correctional-facility-details",
		name: "CorrectionalFacilityDetails",
		component: () =>
			import("@/views/agf/CorrectionalFacilityDetails.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/inmates-list",
		name: "Inmates List",
		component: () =>
			import("@/views/agf/InmatesList.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/inmate-details",
		name: "InmateDetails",
		component: () =>
			import("@/views/agf/InmateDetails.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/correctional-facilities-reports",
		name: "Correctional Facilities Reports",
		component: () =>
			import("@/views/agf/CorrectionalFacilitiesReports.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/arresting-agencies",
		name: "Arresting Agencies",
		component: () =>
			import("@/views/agf/ArrestingAgencies.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/arresting-agency-units",
		name: "Arrests View",
		component: () =>
			import("@/views/agf/ArrestingAgencyUnits.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/arresting-agency-reports",
		name: "Arresting Agency Reports",
		component: () =>
			import("@/views/agf/ArrestingAgencyReports.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/court-cause-list",
		name: "Court Cause List",
		component: () =>
			import("@/views/agf/CourtCauseList.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/court-reports",
		name: "Court Reports",
		component: () =>
			import("@/views/agf/CourtReports.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	// {
	// 	path: "/agf/new-cases",
	// 	name: "New Cases",
	// 	component: () =>
	// 		import("@/views/agf/NewCases.vue"),
	// 	meta: {
	// 		requiresAuth: true,
	// 		layout: AppLayoutDashboard,
	// 		allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
	// 	},
	// },
	// {
	// 	path: "/agf/new-case-details",
	// 	name: "New Case Details",
	// 	component: () =>
	// 		import("@/views/agf/NewCaseDetails.vue"),
	// 	meta: {
	// 		layout: AppLayoutDashboard,
	// 		allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
	// 	},
	// },
	// {
	// 	path: "/agf/new-cases-reports",
	// 	name: "New cases Reports",
	// 	component: () =>
	// 		import("@/views/agf/NewCasesReports.vue"),
	// 	meta: {
	// 		requiresAuth: true,
	// 		layout: AppLayoutDashboard,
	// 		allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
	// 	},
	// },
	{
		path: "/agf/civil-litigation-overview",
		name: "Civil Litigation Overview",
		component: () =>
			import("@/views/agf/CivilLitigationOverview.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/civil-litigation-case-details",
		name: "Civil Litigation Case Details",
		component: () =>
			import("@/views/agf/CivilLitigationCaseDetails.vue"),
		meta: {
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/civil-litigation-reports",
		name: "Civil Litigation Reports",
		component: () =>
			import("@/views/agf/CivilLitigationReports.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/civil-appeal-overview",
		name: "Civil Appeal Overview",
		component: () =>
			import("@/views/agf/CivilAppealOverview.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/civil-appeal-case-details",
		name: "Civil Appeal Case Details",
		component: () =>
			import("@/views/agf/CivilAppealCaseDetails.vue"),
		meta: {
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/civil-appeal-reports",
		name: "Civil Appeal Reports",
		component: () =>
			import("@/views/agf/CivilAppealReports.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/dpp-overview",
		name: "DPP Overview",
		component: () =>
			import("@/views/agf/DPPOverview.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.LAWYER],
		},
	},
	{
		path: "/agf/dpp-case-details",
		name: "DPP Case Details",
		component: () =>
			import("@/views/agf/DPPCaseDetails.vue"),
		meta: {
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/agf/dpp-reports",
		name: "DPP Reports",
		component: () =>
			import("@/views/agf/DPPReports.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/civil-litigation/lawyer/case-view",
		name: "Case View",
		component: () =>
			import("@/views/civil-litigation/lawyer/CaseView.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.DIRECTOR, AccessLevel.LAWYER],
		},
	},
	{
		path: "/civil-litigation/lawyer/case-detail",
		name: "Case Detail",
		component: () =>
			import("@/views/civil-litigation/lawyer/CaseDetail.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.DIRECTOR, AccessLevel.LAWYER],
		},
	},
	//DONE
	//
	//
	//
	//
	//DONE
	{
		path: "/dashboard",
		name: "Dashboard",
		component: () =>
			import("@/views/DashboardView.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.DIRECTOR, AccessLevel.LAWYER, AccessLevel.REGISTRAR],
		},
	},
	{
		path: "/courts",
		name: "Courts",
		component: () =>
			import("@/views/Courts.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/courts/:type",
		name: "Court List",
		component: () =>
			import("@/views/CourtList.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/case-files",
		name: "Case Files",
		component: () =>
			import("@/views/CaseFiles.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.REGISTRAR],
		},
	},
	{
		path: "/civil-litigation-matters/case-files",
		name: "Civil Litigation Case Files",
		component: () =>
			import("@/views/CaseFiles.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/case-files/new",
		name: "New Case Files",
		component: () =>
			import("@/views/CaseFiles.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS],
		},
	},
	{
		path: "/case-files/:id",
		name: "Case File",
		component: () =>
			import("@/views/CaseFile.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.AGF, AccessLevel.PS, AccessLevel.REGISTRAR],
		},
	},
	{
		path: "/add-case-file",
		name: "Add Case File",
		component: () =>
			import("@/views/AddCaseFile.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.REGISTRAR],
		},
	},
	{
		path: "/case-filings",
		name: "Case Filings",
		component: () =>
			import("@/views/CaseFilings.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.REGISTRAR],
		},
	},
	{
		path: "/pending-filings",
		name: "Pending Filings",
		component: () =>
			import("@/views/PendingFilings.vue"),
		meta: {
			requiresAuth: true,
			layout: AppLayoutDashboard,
			allowedAccessLevels: [AccessLevel.REGISTRAR],
		},
	},
	{
		path: '/:pathMatch(.*)*', name: '404',
		component: () =>
			import("@/views/404View.vue"),
		meta: { title: 'Page Not Found', layout: AppLayoutDefault }
	},
]