export class Pagination {
  constructor(pagination, store_action) {
    this.result = pagination['result'] || [];
    this.nextPage = pagination['nextPage'];
    this.previousPage = pagination['previousPage'];
    this.currentPage = pagination['currentPage'] || 1;
    this.totalPages = pagination['totalPages'] || null;
    this.totalCount = pagination['totalCount'] || null;
    this.storeAction = store_action || '';
  }

  createEmpty() {
    return new Pagination({ data: [] });
  }
}