<template>
  <nav class="flex items-center space-x-4" aria-label="BreadcrumbComponent">
    <ol role="list" class="bg-white rounded-md shadow px-6 flex space-x-4">
      <li class="flex">
        <div class="flex items-center">
          <router-link
            to="/dashboard"
            class="text-green-700 hover:text-green-500"
          >
            <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </router-link>
        </div>
      </li>
      <li v-for="(route, index) in routes" :key="index" class="flex">
        <div class="flex items-center">
          <svg
            class="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <router-link
            :to="route.href"
            class="
              ml-4
              text-sm
              font-medium
              text-green-700
              hover:text-green-500
              capitalize
            "
            :aria-current="index ? 'page' : null"
            >{{ route.name }}
          </router-link>
        </div>
      </li>
    </ol>
    <LoadingComponent width="40px" />
  </nav>
</template>

<script>
import { computed, defineComponent } from "vue";
import { HomeIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "BreadcrumbComponent",
  components: { HomeIcon },
  setup() {
    const route = useRoute();
    const routes = computed(() => {
      const x = route.path
        .split("/")
        .filter((route) => route)
        .map((route, index, x) => ({
          name: route.split("-").join(" "),
          href: `/${x.slice(0, index + 1).join("/")}`,
        }));
      return x;
    });

    // const replaceCurrentPage = (currentRoute, name) => {
    //   const placeInArray = routes.value.findIndex(
    //     (route) => route.name == currentRoute
    //   );
    //   routes.value[placeInArray] = name;
    // };

    return { routes };
  },
});
</script>

